import { Button, Center, Heading, Typography, VStack } from "@nestoca/ui";
import { nextI18NextConfig } from "@shared/utils/next-i18next.config";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { NextPageWithLayout } from "@pages/_app";

const FourOneFour = () => {
  const handleBackButton = () => {
    window.history.back();
  };

  const { t } = useTranslation("error");

  return (
    <Center style={{ minHeight: "100vh" }}>
      <VStack>
        <Heading size={1}>{t("404.title")}</Heading>
        <Typography>{t("404.content")}</Typography>
        <Button
          variant="ghost"
          onClick={handleBackButton}
          data-dd-action-name="back"
        >
          {t("404.backButton")}
        </Button>
        <Typography size="00">404 - {t("404.errorName")}</Typography>
      </VStack>
    </Center>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(
        locale ?? "en",
        ["common", "error"],
        nextI18NextConfig
      )),
    },
  };
};

const Page: NextPageWithLayout<never> = FourOneFour;

Page.getLayout = (page) => <>{page}</>;

export default Page;
